.awsm-selectric {
  position: relative;
  display: inline-block;
  width: 100%; 
  background-color: #fff;
  border: 1px solid #dddfe3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  cursor: pointer;
}
.awsm-selectric-selectbox{
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: transparent; 
  border: none; 
  border-bottom: 2px solid #d9d9d9; 
  outline: none;
  border-radius: 4px; 
  box-shadow: none; 
  cursor: pointer;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}
.awsm-selectric-label {
  padding: 0 10px;
  height: 46px; 
  line-height: 46px;
  font-size: 16px;
  color: #696969;
  border: 0.1px solid transparent;
}
:hover.awsm-selectric-label {
  border: 0.1px solid #e5e5e550;
}
.awsm-selectric-arrow-drop {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 100%;
  pointer-events: none;
}
.awsm-selectric-arrow-drop:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  margin-top: -4px;
  border: 5px solid transparent;
  border-top-color: #444;
}
.awsm-selectric-items {
  display: block;
  position: absolute;
  top: 104%; 
  left: 0;
  width: 100%; 
  background: #fff;
  border: 1px solid #dbdbdb;
  z-index: 10; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-top: none;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}
.awsm-selectric-items.open {
  max-height: 300px; 
  opacity: 1;
}
.option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}
.option:hover {
  background-color: #f0f0f0; 
}
/* responsive */
.selecet-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}
.selectbox {
  max-width: 300px;
}
@media (max-width: 992px) and (min-width: 768px) {
  .selecet-container {
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .selecet-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
