.goodfirms-badge {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 100px;
  z-index: 999;
}
@font-face {
  font-family: "Graphik-Regular";
  src: url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Regular.eot");
  src: url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Regulard41d.eot?#iefix")
      format("embedded-opentype"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Regular.woff2")
      format("woff2"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Regular.woff")
      format("woff"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Regular.ttf")
      format("truetype"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Regular.svg#Graphik-Regular")
      format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Graphik-Medium";
  src: url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Medium.eot");
  src: url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Mediumd41d.eot?#iefix")
      format("embedded-opentype"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Medium.woff2")
      format("woff2"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Medium.woff")
      format("woff"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Medium.ttf")
      format("truetype"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Medium.svg#Graphik-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Graphik-Semibold";
  src: url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Semibold.eot");
  src: url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Semiboldd41d.eot?#iefix")
      format("embedded-opentype"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Semibold.woff2")
      format("woff2"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Semibold.woff")
      format("woff"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Semibold.ttf")
      format("truetype"),
    url("/public/wp-content/themes/wp-bootstrap-4/assets/fonts/Graphik-Semibold.svg#Graphik-Semibold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

html body {
  font-family: "Graphik-Regular", sans-serif;
  font-size: 17px;
  overflow-x: hidden;
  /* user-select: none; */
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: "Graphik-Semibold", sans-serif;
  line-height: 1.4;
  font-weight: 400;
}
body .l-height {
  line-height: 1.4;
}
img {
  max-width: 100%;
}
a:hover {
  text-decoration: none;
}
.cursor-disable:hover {
  cursor: not-allowed !important;
  background-color: gray !important;
}
/* button */
body .btn-round {
  padding: 10px 25px;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  background-color: #fe3a45;
  border-color: #fe3a45;
  font-weight: 400;
}
body .btn-primary:hover {
  background-color: #e93841 !important;
  border-color: 5px solid #04347b;
}

.btn-round img {
  width: 15px;
}
body input[type="submit"] {
  background-color: #fe3a45 !important;
  color: #fff;
  padding: 7px 20px;
  border: 1px solid #e93841;
  border-radius: 25px;
}
body .text-primary {
  color: #fe3a45 !important;
}
body .text-secondary {
  color: #04347b !important;
}
.btn-secondary {
  color: #fff;
  background-color: #04347b;
  border-color: #04347b;
}

/* Header  */
.main-navigation .navbar.bg-dark {
  background-color: transparent !important;
}
body .main-navigation {
  background-color: #04347b;
  justify-content: space-between;
}
.home .main-navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px;
  z-index: 1000;
  padding: 5px 0px;
  background-color: transparent;
}
.navigation-menu ul li a.nav-menu {
  display: block;
  padding: 6px 14px;
  color: #fff;
  position: relative;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .main-navigation.fixed-top {
  position: fixed !important;
  top: 0 !important;
  background-color: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
}
body .main-navigation.fixed-top .navigation-menu ul li a.nav-menu {
  color: #000;
}
body .main-navigation .navigation-menu ul li a.nav-menu:hover {
  color: #fe3a45;
}
body .main-navigation .navigation-menu ul li a.nav-menu:focus {
  outline: none;
  border: none;
}
body .main-navigation .navigation-menu ul li a.nav-menu:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fe3a45;
  height: 2px;
  width: 0;
  transition: 0.4s ease;
  margin: 0 auto;
  content: "";
}
body .main-navigation .navigation-menu ul li:hover a.nav-menu:before {
  width: 100%;
}
body .main-navigation .navigation-menu ul li a.nav-menu:before {
  top: -10px;
}
body .main-navigation.fixed-top .navigation-menu ul li a.nav-menu:before {
  top: 0px;
}
.site-logo .darklogo {
  display: none;
}
.site-logo .darklogo img {
  transition: all 0.3s ease 0s;
  height: 110px;
  width: auto;
}
body .main-navigation.fixed-top .site-logo .darklogo img {
  height: 70px;
  width: auto;
}
body .main-navigation.fixed-top .site-logo .darklogo {
  display: block;
}
body .main-navigation.fixed-top .site-logo .custom-logo-link {
  display: none;
}

.main-navigation .navbar-nav .dropdown-menu {
  transition: 0.3s ease;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  visibility: hidden;
  display: block;
}
.dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transform: scaleY(1);
}
.fixed-top {
  animation: slide-down 0.7s;
  opacity: 1;
}
@keyframes slide-down {
  0% {
    opacity: 5;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.mouse_scroll {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 24px;
  height: 100px;
  bottom: -70px;
}
.m_scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid #1e3a63;
  border-bottom: 2px solid #1e3a63;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}
.unu {
  margin-top: 1px;
}
.doi,
.trei,
.unu {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;
}
.unu {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  animation-delay: alternate;
}
.doi {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  animation-delay: 0.2s;
  animation-direction: alternate;
  margin-top: -6px;
}
.trei {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  animation-delay: 0.3s;
  animation-direction: alternate;
  margin-top: -6px;
}
.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid #1e3a63;
  top: 170px;
}
.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: #1e3a63;
  position: relative;
  height: 4px;
  width: 4px;
  border: 2px solid #1e3a63;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}
.navbar-nav .dropdown.fullmenu {
  position: static;
}
.megamenu,
.navbar-nav .dropdown-menu {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
}
.navbar-nav .dropdown-menu.bigmenu {
  min-width: 100%;
  left: 0px;
  right: 0;
  margin: 0;
  margin-top: -2px;
  padding: 0px;
  background: transparent;
  border: none;
  box-shadow: none;
}
.navbar-nav .dropdown-menu {
  border: none;
  border-radius: 0;
  margin: 0;
  border: 1px solid #ddd;
}
.megamenu {
  margin: auto;
  background-color: #fff;
  padding: 30px;
  border-top: 1px solid #eee;
}
.megamenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.megamenu ul li a {
  color: #000;
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out 0s;
}
.megamenu.hiretab ul li a {
  margin-bottom: 0px;
}
.megamenu ul li a:hover {
  color: #fe3a45;
  padding-left: 3px;
}
.dropdown-menu .dropdown-item {
  padding: 8px 20px;
  color: #000;
  font-size: 16px;
}
.dropdown-menu .dropdown-item:hover {
  color: #fe3a45;
}
.megamenu ul li a img {
  display: inline-block;
  width: 30px;
  margin-right: 7px;
  display: none;
}
.megamenu .menu-title {
  margin-bottom: 20px;
  font-size: 19px;
  color: #04347b;
  position: relative;
}
.megamenu .menu-title:hover::after {
  width: 100px !important;
}
.megamenu .menu-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 45px;
  height: 3px;
  background-color: #fe3a45;
  transition: all 0.3s ease-in-out;
}
.fixed-headder.menu-open {
  position: relative;
}
.fixed-headder.menu-open::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.custom-logo-link {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
}
.dropdown-toggle::after {
  margin-left: 6px;
  margin-top: 4px;
}
.opacity-0 {
  opacity: 0;
}
.list-style-dot {
  padding-left: 20px;
}
.list-style-dot li {
  margin-bottom: 10px;
}
.pagettile h1 {
  font-size: 34px;
}
/* homepage  */
.pagebanner {
  background-image: url("/public/wp-content/themes/wp-bootstrap-4/assets/images/banner-1.png");
  min-height: 90vh;
  background-size: cover;
  background-position: bottom left;
  padding: 170px 0px 70px;
  position: relative;
}
.pagebanner .scrolldown {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center;
  width: 50px;
  margin: auto;
}
.pagebanner .row {
  min-height: 60vh;
}
.sblocks p {
  min-height: 78px;
}
@media (min-width: 1290px) {
  body .container,
  body .megamenu {
    max-width: 1200px;
  }
}
@media (min-width: 1599px) {
  body .container,
  body .megamenu {
    max-width: 1440px;
  }
  .sblocks p {
    min-height: 52px;
  }
}
@media (max-width: 1599px) {
  body {
    font-size: 16px;
  }
}
.banner-titles h1 {
  font-size: 32px;
  margin-bottom: 15px;
  line-height: 1.5;
}
/* Footer  */
.site-footer {
  background-image: url("/public/wp-content/themes/wp-bootstrap-4/assets/images/footer-bg.png");
  min-height: 200px;
  background-size: cover;
  background-position: center center;
  padding: 70px 0px 30px;
}
.site-footer p {
  font-size: 16px;
  font-weight: 300;
}
.footer-actions {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.footer-actions a {
  background-color: #2e5795;
  border-radius: 50px;
  height: 60px;
  min-width: 240px;
  display: flex;
  align-items: center;
  padding: 0px 30px;
  font-weight: 400;
}
.footer-actions a:hover {
  background-color: #244b85;
}
.footer-actions .text-muted {
  color: #cccccc !important;
  font-size: 13px;
}
.site-footer .widgets {
  margin-top: 60px;
  padding-left: 30px;
}
.site-footer .widgets .widgettitle {
  color: #78a5e6;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.site-footer .widgets .quicklinks {
  list-style: none;
  padding: 0;
}
.site-footer .widgets .quicklinks a {
  display: block;
  padding: 5px 0px;
  font-size: 16px;
  font-weight: 300;
}
.site-footer .bottom-footer {
  border-top: 1px solid #4c7abf;
  padding-top: 15px;
  margin-top: 15px;
}
.site-footer .sociallinks {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
}
.site-footer .sociallinks a {
  padding: 6px 3px;
  display: block;
  margin-right: 9px;
  font-size: 32px;
}
/* about-info */
.about-info {
  padding: 90px 0px 0px;
}
.section-title:hover .dividebar {
  width: 250px !important;
}
.section-title h2 {
  margin-bottom: 10px;
  font-weight: bold;
}
.dividebar {
  background-color: #fe3a45;
  height: 3px;
  width: 140px;
  margin: auto;
  transition: all 0.3s ease-in-out;
}
.floating {
  animation-name: floating;
  -webkit-animation-name: floating;
  animation-duration: 2.5s;
  -webkit-animation-duration: 2.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes floating {
  0% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
.bottminfo a,
.bottminfo {
  color: #fff;
}
.bottminfo > div,
.bottminfo > a {
  margin-right: 10px;
  font-size: 15px;
  font-weight: 200;
}
/* Services  */
.shapes-bg {
  width: 100%;
}
.our-services {
  background: #eff5fd;
  padding: 0px 0px 50px;
}
.service-card {
  background: #fff;
  text-align: center;
  padding: 25px 15px 45px;
  position: relative;
  display: block;
  color: #000;
  margin-bottom: 30px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.service-card .service-icon {
  position: relative;
  min-height: 100px;
}
.service-card .backbg {
  position: absolute;
  margin-left: -15px;
}
.service-card h4 {
  color: #233d63;
}
.service-card .mainicon {
  position: relative;
  z-index: 10;
}
.morebtn {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  bottom: -30px;
  opacity: 0;
  transition: all 0.2s ease-in-out 0s;
  z-index: 99;
}
.service-card:hover {
  color: #000;
}
.service-card:hover .morebtn {
  opacity: 1;
}
.service-card::after,
.service-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.7s ease 0s;
  border-radius: 4px;
}
.service-card::before {
  border-bottom: 2px solid #fe3a45;
  border-top: 2px solid #fe3a45;
  transform: scale(0, 1);
  transform-origin: 0 0 0;
}
.service-card::after {
  border-left: 2px solid #fe3a45;
  border-right: 2px solid #fe3a45;
  transform: scale(1, 0);
  transform-origin: 100% 0 0;
}
.service-card:hover::after,
.service-card:hover::before {
  opacity: 1;
  transform: scale(1);
  transition-delay: 0.15s;
}
.servi-text p {
  min-height: 80px;
}
/* Service End */
/* technologies section */
.technologies-section {
  padding: 60px 0px;
}
.technologies-section .nav-pills {
  background-color: #fff;
  justify-content: space-between;
  width: 100%;
  border-radius: 50px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
  margin-top: 35px;

}
.technologies-section .nav-pills li button {
  padding: 13px 30px;
  font-size: 18px;
  transition: all 0.2s ease-in-out 0s;
  font-weight: 500;
  border-radius: 50px;
  text-align: center;
  color: #233d63;
}
.technologies-section .nav-pills .nav-link.active {
  background-color: #fe3a45 !important;
}
.techlist {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin-top: 25px;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;
}
.techlist li {
  flex: 0 0 auto;
  width: 20%;
  padding: 15px;
}
.tech-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.techicon {
  display: block;
  background: url("/public/wp-content/themes/wp-bootstrap-4/assets/images/tech-icons.webp")
    no-repeat;
  flex-shrink: 0;
}
.techmenuicon {
  transform: scale(0.5);
  width: 60px;
}
.tech-card .techwrap {
  min-height: 75px;
  display: flex;
  align-items: center;
}
.tech-card img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}
.tech-card span {
  font-weight: 500;
  color: #1e3a63;
}
/* Contact section  */
.connect-us {
  padding: 60px 0px 80px;
  background: #e8f1ff;
}
.contact-information h5 {
  color: #fe3a45;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  padding-top: 20px;
}
.contact-information h5 img {
  margin-right: 15px;
}
.contact-information ul {
  list-style: none;
  padding: 0;
}
.connect-us ul li {
  color: #233d63;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.connect-us ul li img {
  flex: 0 0 auto;
  width: 28px;
  margin-right: 15px;
}
/* General Form Styles */
.contacts .form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}
/* CSS COPY FROM STYLE */
.wpcf7-form-control {
  width: 100%;
  height: 48px !important;
  margin-bottom: 15px;
  -webkit-border-left: none !important;
  border-left: none !important;
  -webkit-border-right: none !important;
  border-right: none !important;
  -webkit-border-top: none !important;
  border-top: none !important;
  -webkit-border-bottom: 2px solid #d9d9d9;
  border-bottom: 2px solid #d9d9d9;
  outline: 0;
  border-radius: 0;
  font-size: 1rem;
  color: #696969;
  padding: 7px 0;
  background: 0 0;
  transition: border-color 0.2s;
}
.contacts .wpcf7-form-control {
  width: 100%;
  height: 20px;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  outline: none;
  border-radius: 0;
  font-size: 1rem;
  color: #696969;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.3s ease;
}
.contacts .wpcf7-form-control:focus {
  border-bottom-color: 2px solid #d9d9d9;
}
.contacts .form__label {
  position: absolute;
  top: 30%;
  left: 0;
  display: block;
  transition: 0.3s ease;
  font-size: 1rem;
  color: #696969;
  pointer-events: none;
}
.error {
  font-size: 0.875rem;
  margin-top: -5px; /* Add some space between the input and the error message */
}
/* Error styling for input fields */
.input-error {
  border-color: red; /* Change border color to red for error state */
}
/* Error message styling */
.error-message {
  color: red; /* Red color for error text */
  font-size: 13px; /* Font size of 10px for error text */
  margin-top: 2px; /* Space between input field and error message */
}
.contacts .wpcf7-form-control:focus + .form__label,
.contacts .wpcf7-form-control:not(:placeholder-shown) + .form__label {
  top: 0;
  font-size: 0.75rem;
  color: #1e3a63;
  display: block;
  transition: 0.2s;
  font-size: 14px;
  font-weight: 500;
}
/* Dropdown Field */
.dropdownfield .form__label {
  top: 0;
  font-size: 0.75rem;
  color: #1e3a63;
  border-bottom-color: 2px solid #d9d9d9;
}
/* Textarea */
.contacts .wpcf7-form-control.wpcf7-textarea {
  height: 100px;
}
.contacts .wpcf7-form-control.wpcf7-textarea:focus {
  border-bottom-color: 2px solid #d9d9d9;
}
.submitbtn [type="submit"] {
  display: inline-block;
  text-align: center;
  overflow: hidden;
  line-height: 1;
  color: #fff;
  background-color: #fe3a45;
  position: relative;
  border-radius: 25px;
  padding: 12px 35px;
  font-weight: 500;
  font-size: 15px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  border: 2px solid #fe3a45;
  width: auto;
  height: auto;
  cursor: pointer;
}
.submitbtn [type="submit"]:hover {
  /* background-color: transparent;
  color: white; */
  /* background-color: #fff;
  color: #fff ;
  border-color: #fe3a45; */
}
/* Container Styles */
.contactbox {
  background-color: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
  padding: 15px 30px;
  border-radius: 10px;
  margin-top: 15px;
}
.contactpage h5 {
  margin-top: 30px;
}
/* CONTACT VALI CSS CHECK  */
/* blogs  */
.blogs .edelta-blog {
  width: 31.3%;
  float: left;
  margin-right: 2%;
}
.edelta-blog .post-thumbnail img {
  height: auto;
  max-width: 100%;
}
.pagettile {
  padding: 30px 0px 30px;
  background-image: url("/public/wp-content/themes/wp-bootstrap-4/assets/images/innerpage-bg.png");
  min-height: 210px;
  background-size: cover;
  background-position: center bottom;
  display: flex;
  align-items: center;
}
.bredcumbss {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
  justify-content: center;
}
.bredcumbss li {
  position: relative;
  margin-right: 20px;
  color: #b4b4b4;
}
.bredcumbss li a {
  color: #fff;
  display: block;
}
.bredcumbss li:after {
  content: "";
  position: absolute;
  right: -12px;
  width: 5px;
  height: 5px;
  background-color: #e93841;
  border-radius: 50%;
  top: 11px;
}
.bredcumbss li:last-child {
  margin-right: 0px !important;
}
.bredcumbss li:last-child::after {
  display: none;
}
/* GO TO TOP */
#go-to-top {
  right: 15px;
  z-index: 999;
  border-radius: 50px;
  border: 2px solid #e93841;
  width: 40px;
  height: 40px;
  display: block;
  position: fixed;
}
#go-to-top canvas {
  width: 12px;
  height: 12px;
  border-bottom: 2px solid #e93841;
  border-right: 2px solid #e93841;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  position: absolute;
  top: 15px;
  left: 12px;
}
/* about section  */
.staticbox {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  width: 180px;
  background-color: #fff;
  height: 150px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.staticbox p {
  margin-bottom: 0px;
}
.staticbox h3 {
  margin-top: 15px;
  font-weight: 700;
  font-size: 28px;
}
.company-statics {
  display: flex;
  flex-wrap: wrap;
  width: 510px;
  height: 370px;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  background-image: url("/public/wp-content/themes/wp-bootstrap-4/assets/images/edelta-about1.jpg");
  background-size: contain;
  background-position: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.company-statics .staticbox.sb3 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 99;
}
.company-statics .staticbox.sb4 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
}
.company-statics .staticbox.sb5 {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-right: 0;
  z-index: 10;
}
.aboutus-section {
  padding: 70px 0px 70px;
}
.bg-section {
  background-color: #eff5fd !important;
}
.mission-vision-section {
  background-color: #eff5fd;
  padding: 70px 0px 70px;
}
.section-title.text-left .dividebar {
  margin-left: 0px;
}
.section-title.text-left .dividebar {
  margin-left: 0px;
}

.in-nutsell {
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
  margin-top: 30px;
}
.in-nutsell .cols {
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 14%;
}
.in-nutsell .cols .staticbox {
  width: 100%;
  height: auto;
  padding: 14px;
  min-height: 180px;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.in-nutsell .staticbox i {
  font-size: 34px;
  min-height: 43px;
  color: #fe3a45;
}
.whychoosemedia i {
  font-size: 54px;
  color: #fe3a45;
}
.whychoosemedia {
  padding: 30px 20px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
  min-height: 149px;
  margin-bottom: 30px;
  border-radius: 15px;
}
.leaderbox {
  padding: 30px 20px;
  margin-bottom: 30px;
  border-radius: 15px;
  background: #e5effb;
  text-align: center;
  min-height: 580px;
}
.leaderbox .laederheader {
  background: #083981;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
}
.leaderbox .laederheader h4 {
  margin-bottom: 3px !important;
}
.leaderbox .laederheader .text-muted {
  color: #cccc !important;
}
.folloinstagram {
  color: #233d63;
  margin-top: 10px;
  display: inline-block;
  font-weight: 600;
}
.folloinstagram:hover {
  color: #e93841;
}
.g-item {
  margin-bottom: 30px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
  display: block;
  overflow: hidden;
  position: relative;
}
.g-item img {
  width: 100%;
  height: 370px;
  object-fit: cover;
  transition: all 0.3s ease-in-out 0s;
  transform: scale(1);
}
.g-item:hover img {
  transform: scale(1.1);
}
.g-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  opacity: 0;
}
.g-item:hover::before {
  opacity: 1;
}
.join-us {
  display: flex;
  align-items: center;
  width: 80%;
  background: #eff5fd !important;
  padding: 30px;
  justify-content: space-between;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}
.cardloction {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 20px;
  text-align: center;
}
.flagimg {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background-color: #fff;
  width: 45px;
  height: 45px;
  margin: 0px auto 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardloction p {
  font-size: 16px;
  min-height: 96px;
}
.quickcontact ul {
  padding: 0px;
  background-color: #fff;
  margin: 0;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.quickcontact ul li {
  padding: 20px 30px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0px !important;
  color: #04347b;
}
.job-hired {
  padding: 70px 0px;
}

.office-contact ul {
  list-style: none;
  padding: 0;
}
.office-contact ul li {
  padding: 10px;
}
.office-contact ul li > img {
  width: 21px;
  margin-right: 10px;
}
.lcatinmap {
  color: #e93841;
  font-size: 17px;
  border: 1px solid #e93841;
  padding: 7px 20px;
  border-radius: 25px;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
}
.lcatinmap i {
  margin-right: 7px;
}
/* blog  */
.projeheader .post-thumbnail > img {
  width: 100%;
  height: auto;
}
.blog_body .entry-header,
.blog_body .entry-summary,
.entry-content {
  padding-left: 15px;
  padding-right: 15px;
}
.blog_body .entry-summary {
  padding-bottom: 15px;
}
.blog_list {
  background: #fff;
  display: block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.artcalgrid {
  display: flex;
  flex-wrap: wrap;
}
.artcalgrid .blog_thumbnails img {
  height: 300px;
  object-fit: cover;
}
.sandip_blog .entry-header h2 {
  font-size: 21px;
}
.sandip_blog .entry-header h2 a {
  height: 50px;
  overflow: hidden;
}
.sandip_blog {
  width: 50%;
  padding: 15px;
  margin-top: 40px;
}
.single .sandip_blog {
  width: 100%;
  margin-top: 3rem;
  padding: 0;
}
.sandip_blog .entry-summary p {
  color: #666666;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.readmore_btn {
  color: #000;
}
.readmore_btn:hover {
  text-decoration: none;
  color: #555;
}
.page_inner_title {
  background: #31328b;
  text-align: center;
  padding: 50px 0px;
}
.page_title {
  color: #fff;
  text-transform: uppercase;
  margin: 0px;
}
.sidebar-1-area {
  border: none !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.post_date .btn-primary {
  border-radius: 20px;
  padding: 6px 15px;
  color: #fff;
}
.post_date .btn-primary:hover {
  color: #fff;
}
.blog_metas {
  margin-top: -20px;
  padding-left: 15px;
}
.single .blog_metas {
  margin-top: 20px;
  padding-left: 0px;
}
.single .entry-content {
  padding-left: 0px;
}
.single .entry-header {
  background: #e2ecfc;
  padding: 10px 20px !important;
}
.single .blog_thumbnails {
  margin-bottom: 30px;
}
.single .entry-header h1 {
  margin-bottom: 0px;
  font-size: 25px;
}
.auth_n_cat {
  margin-top: 15px;
  margin-bottom: 10px;
  float: left;
  width: 100%;
}
.sperator {
  width: 20px;
  text-align: center;
  color: #999;
}
.auth_n_cat span {
  float: left;
  line-height: 1;
}
.tags-links {
  padding-left: 15px;
}
.data_blog h3 {
  color: #262626;
  font-size: 20px !important;
}
.data_blog p {
  color: #959595;
}
.data_blog {
  padding: 15px 15px 30px 15px;
}
.nav-links {
  display: flex;
  justify-content: space-between;
}
.nav-links a {
  display: inline-block;
  padding: 6px 10px;
  color: #04347b;
  border: 1px solid #04347b;
  border-radius: 25px;
  margin-top: 15px;
}
.comments-area.card {
  border: none !important;
  margin-top: 30px;
}
.single .archivemeta {
  display: none;
}
.logged-in-as {
  padding-left: 15px;
  margin-top: 40px;
}
.single .blog_list {
  box-shadow: none;
}
.cate_badge {
  padding-left: 10px;
  border-left: 1px solid #ddd;
  margin-left: 10px;
}
.service-infobox {
  margin-bottom: 30px;
}
.service-infobox img {
  height: 90px;
  width: auto;
  margin-bottom: 24px;
}
.enterprise-solution {
  padding: 30px 0px 70px;
}
.solutionblocks {
  display: flex;
}
.solutionblocks .sblocks {
  flex: 0 0 auto;
  width: 50%;
  color: #fff;
  padding: 40px 30px;
  margin-bottom: 30px;
}
.solutionblocks .sblocks.bg1 {
  background-color: #414a54;
}
.solutionblocks .sblocks.bg2 {
  background-color: #e93841;
}
.sblocks {
  text-align: center;
}
.sblocks h3 {
  margin-bottom: 20px;
}
.sblocks .simg {
  max-width: 400px;
  margin: 30px 0px 30px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}
.sblocks ul {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
  margin-left: auto;
  margin-top: 20px;
}
.sblocks li {
  flex: 0 0 auto;
  width: 50%;
  margin-bottom: 10px;
  text-align: left;
}
.single-awsm_job_openings .blog_thumbnails {
  display: none;
}
body .awsm-job-form-control {
  border: 1px solid #ddd;

  outline: none;
  padding: 6px 10px;
  border-radius: 5px;
}
/* career */
.list-icons {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 30px;
}
.list-icons li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.list-icons li i {
  width: 40px;
  font-size: 20px;
  margin-right: 5px;
  color: #04347b;
  text-align: center;
}
/* hiredevelpoer */
.hirelist {
  list-style: none;
  padding: 0px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.hirelist li {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 50%;
}
.hire-section {
  padding: 60px 0px;
}
.featurebox {
  text-align: center;
}
.using-chatbot ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  list-style: none;
  padding: 0px;
  margin: 0;
  align-items: center;
}
.using-chatbot ul li {
  flex: 0 0 32%;
  margin-bottom: 20px;
  text-align: left;
  padding: 20px;
  position: relative;
}
.chatbotservies {
  background: #eff5fd;
  padding: 70px 0px 0px;
}
.using-chatbot p {
  position: relative;
  padding: 0 0 30px 80px;
}
.using-chatbot p::before {
  content: "\f058";
  font: 34px/20px FontAwesome;
  color: #04347b;
  position: absolute;
  left: 24px;
  top: 16px;
}
.prodcard {
  padding: 30px;
  border-radius: 20px;
  background: #eff5fd;
  position: relative;
}
.prodcard > img {
  position: absolute;
  top: -30px;
  left: -30px;
  z-index: -1;
}
.whyusproduct-section {
  padding: 80px 0px 50px;
}
.uititle {
  width: 100%;
  display: inline-block;
  margin: 180px 0px 0px 0px;
}
.uistep-section {
  background: #eff5fd;
  padding: 50px 0px 50px;
}
.uistep-section .designsteps {
  background: #fff;
  margin-bottom: 70px;
  display: inline-block;
  width: 100%;
}
.uistep-section .stepimg {
  background: #f6f7f8;
  padding: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.12);
}
.uistep-section .dec-texts {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.uistep-section .dec-texts ul {
  list-style: none;
  margin-top: 30px;
}
.uistep-section .dec-texts li {
  position: relative;
  margin-bottom: 15px;
}
.uistep-section .dec-texts li::before {
  content: "\f058";
  font: 23px FontAwesome;
  color: #04347b;
  position: absolute;
  left: -40px;
  top: 2px;
}
.customsoftware-section {
  position: relative;
  z-index: 1;
  padding: 80px 0px 70px;
  background: #04347b;
}
.customsoftware-section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: url("/public/wp-content/themes/wp-bootstrap-4/assets/images/software.png")
    center no-repeat;
  background-size: auto 50%;
}
.customsoftware-section ul.alignRight {
  text-align: right;
}
.customsoftware-section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.customsoftware-section ul li {
  display: block;
  margin-bottom: 40px;
}
.customsoftware-section ul li p {
  font-weight: 300;
}
.allexaapplication {
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 30px;
}
.servicecard {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  margin-bottom: 30px;
}
.servicecard .sercardinfo {
  padding: 20px;
  min-height: 150px;
}
.ecomserv-section {
  padding: 70px 0px 60px;
  background: #04347b;
}
.edcationcard .servicecard .iconservce {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edcationcard .servicecard {
  min-height: 396px;
  border-radius: 10px;
}
.web-block {
  display: inline-block;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 0px 0;
  margin-bottom: 0px;
  position: relative;
  top: 0px;
  left: 0px;
  transition: all 0.3s ease-in-out;
}
.web-block h4 {
  font-size: 18px;
  color: #222222;
  font-weight: 500;
  margin: 20px 0;
  min-height: 60px;
  line-height: 25px;
}
.web-block .icon {
  display: inline-block;
  width: 100%;
  padding: 23px 0 9px;
  min-height: 110px;
}
.web-block:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 30px;
  background: url("/public/wp-content/themes/wp-bootstrap-4/assets/images/divider-2.png");
  right: 0px;
  top: 0px;
  right: -25px;
}
.web-block.border-bottom {
  border-bottom: 1px solid #ededed !important;
}
.bgimgs {
  background-color: #e93841;
  padding: 30px;
  border-radius: 30px;
}
.heathcares .whychoosemedia i {
  font-size: 33px;
  color: #fff;
  background: #083981;
  padding: 21px;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.morebtnhire {
  margin-top: 20px;
}
.portfolio-grids
  .vp-filter__style-default
  .vp-filter__item.vp-filter__item-active
  > * {
  background-color: #fe3a45;
  border-color: #fe3a45;
}
.portfolio-grids .vp-filter__style-default .vp-filter__item a {
  min-width: 100px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 25px;
}
.termslist {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
}
.termslist li {
  border: 1px solid #04347b;
  border-radius: 25px;
  padding: 8px 21px;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #04347b;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.portfoliotitle {
  font-size: 23px;
}
.projectheader {
  padding: 30px 0px 10px;
  background-color: #d5e6ff;
}
.bg-light-primary {
  background-color: #d5e6ff;
}
.portfolio-screenshot {
  padding: 30px;
  background-color: #f5f5f5;
  text-align: center;
  margin-top: 30px;
}
.projeheader {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.project_thumbnails {
  flex: 0 0 auto;
  width: 150px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.22);
  margin-bottom: 20px;
  border-radius: 15px;
  overflow: hidden;
}
.projectheader .project-info {
  padding-left: 20px;
  flex: 0 0 auto;
  width: calc(100% - 150px);
}
.portfoliotitle {
  display: flex;
  align-items: center;
}
.portfoliotitle .project-type {
  font-size: 16px;
  color: #555;
  margin-left: 10px;
  font-weight: 400;
  font-family: "Graphik-Regular", sans-serif;
}
.portfoliotitle .project-type span {
  display: inline-block;
  padding-right: 10px;
}
.portfolio-screenshot img {
  width: 100%;
}
.loaderwraper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: #ffff;
  transition: all 0.5s ease-in-out 0s;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading {
  overflow: hidden;
}
.loaderwraper.hideloader {
  top: -100%;
  height: 0px;
}
.loading .site {
  opacity: 0;
  transition: all 0.5s ease-in-out 0s;
}
.list-links {
  list-style: none;
  padding: 0px;
  margin: 0;
}
.list-links li a {
  color: #04347b;
  display: block;
  padding: 5px 0px;
}
.list-links li a:hover {
  color: #e93841;
}
.list-links h4 {
  font-size: 18px;
}
.single.single-awsm_job_openings .nav-links {
  display: none;
}
/* Mobile menu  */
.mob-navigations {
  display: none !important;
}
@media (max-width: 1500px) {
  .pagettile {
    min-height: 161px;
  }
  .artcalgrid .entry-header {
    min-height: 110px;
  }
  .service-card h4 {
    font-size: 20px;
  }
  body .main-navigation.fixed-top .site-logo .darklogo img {
    height: 68px;
  }
  .navigation-menu ul li a.nav-menu {
    min-height: 66px;
  }
}
@media (max-width: 1100px) {
  .company-statics {
    width: 464px;
  }
}
@media (max-width: 992px) {
  .company-statics {
    margin-left: auto;
    margin-right: auto;
  }
  .main-navigation {
    display: none !important;
  }
  .mob-navigations {
    display: block !important;
  }
  .mob-navigations {
    background-color: #fff !important;
  }
  .menu-toogle {
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    flex: 0 0 auto;
    width: 30px;
  }
  .menu-toogle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  .menu-toogle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #083981;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }
  .menu-toogle span:first-child {
    transform-origin: 0% 0%;
  }
  .menu-toogle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
  .menu-toogle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-7px, -7px);
    background: #36383f;
  }
  .menu-toogle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  .menu-toogle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(-8px, 10px);
  }
  .mobileheader {
    padding: 8px 15px;
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
  }
  .mobileheader .site-logo {
    flex: 0 0 auto;
    width: calc(100% - 40px);
  }
  .mobileheader .site-logo img {
    max-width: 150px;
  }
  /* mobilescreen dropdown */
  .collpasemenu {
    position: fixed;
    right: -100%;
    width: 100%;
    background-color: #f2f8ff;
    z-index: 9999;
    top: 75px;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.12);
    bottom: 0;
    border-top: 1px solid #eee;
    transition: all 0.3s ease 0s;
    padding: 15px;
    overflow: auto;
  }
  .collpasemenu.show {
    right: 0;
  }
  .submenu a {
    display: flex;
    justify-content: space-between;
  }
  .submenu a canvas {
    width: 12px;
    height: 12px;
    border-bottom: 2px solid #083981;
    border-right: 2px solid #083981;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .collpasemenu .navbar-nav li a {
    color: #000;
    display: flex;
    padding: 8px 15px;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    font-weight: 500;
  }
  .collpasemenu .navbar-nav li a.collapsed canvas {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .submenu > .nav-menu {
    background-color: #d5e6ff;
  }
  .collpasemenu .navbar-nav .sub-menus li a {
    padding-left: 0px;
  }
  .collpasemenu .footer-actions a {
    margin-bottom: 15px;
    color: #fff;
    background-color: #04347b;
    width: 100%;
  }
  /* end menu  */
  .allexaapplication h2 {
    margin-top: 10px;
  }
  h2 {
    font-size: 22px;
  }
  .pagebanner {
    padding: 100px 0px 70px;
    min-height: auto;
  }
  .banner-titles h1 {
    margin-bottom: 15px;
    font-size: 21px;
  }
  .mouse_scroll {
    display: none;
  }
  .about-info {
    padding: 50px 0px 50px;
  }
  .technologies-section .nav-pills {
    flex-wrap: nowrap;
    overflow: auto;
  }
  .technologies-section .nav-pills li a {
    padding: 10px 17px;
    font-size: 15px;
    min-width: auto;
    white-space: nowrap;
  }
  .section-title h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }
  .bubbles {
    display: none;
  }
  .testimony-slider {
    margin-top: 30px;
  }
  .testimony-slider .carousel-item {
    padding: 10px;
  }
  .arrowbtn.right {
    right: -7px;
    width: 24px;
  }
  .arrowbtn.left {
    left: -7px;
    width: 24px;
  }
  .techlist li {
    width: 25%;
  }
  .tech-card img {
    height: 35px;
    width: 35px;
  }
  .sitelogo {
    width: 200px;
  }
  .footer-actions {
    margin-top: 40px;
    justify-content: flex-start;
  }
  .footer-actions a {
    margin-bottom: 15px;
  }
  .site-footer {
    padding: 40px 0px 40px;
  }
  .awards {
    margin-bottom: 20px;
  }
  .widgets.mt-0.ml-auto {
    margin-left: 0px !important;
  }
  .site-footer .widgets {
    padding-left: 0px;
    margin-top: 40px;
  }
  .client-testimonial {
    padding: 50px 0px 5px;
  }
  .our-services {
    padding-top: 30px;
  }
  .pagettile {
    margin-top: 60px;
  }
  .pagettile h1 {
    font-size: 24px;
  }
  .portfoliotitle {
    font-size: 20px !important;
  }
  .q-connect h3 {
    font-size: 20px;
  }
  body {
    font-size: 16px;
  }
  .customsoftware-section:before {
    display: none;
  }
  .customsoftware-section ul.alignRight {
    text-align: left;
  }
  .customsoftware-section h4 {
    font-size: 20px;
  }
  .solutionblocks {
    flex-wrap: wrap;
  }
  .solutionblocks .sblocks {
    width: 100%;
  }
  .sblocks .simg {
    max-width: 100%;
  }
  .sblocks ul {
    width: 100%;
  }
  .sblocks ul li {
    width: 100%;
  }
  .partnerus h4 {
    margin-top: 30px;
  }
  .hirelist li {
    width: 100%;
  }
  .hire-info {
    margin-bottom: 30px;
  }
  .in-nutsell {
    flex-wrap: wrap;
  }
  .in-nutsell .cols {
    width: 33.333%;
  }
  .in-nutsell .staticbox {
    margin-bottom: 30px;
  }
  .projectheader {
    padding: 97px 0px 10px;
  }
  .testiwrapper .testimonoialinfo {
    width: 210px;
  }
  .testiwrapper .testi-text {
    width: calc(100% - 210px);
  }
}
@media (max-width: 767px) {
  .techlist li {
    width: 50%;
  }
  .company-statics {
    width: 100%;
    height: auto;
    justify-content: center;
  }
  .company-statics .staticbox.sb3,
  .company-statics .staticbox.sb4,
  .company-statics .staticbox.sb5 {
    position: static;
  }
  .company-statics .staticbox.sb3 {
    position: static;
  }
  .company-statics .staticbox {
    width: 160px;
    height: 160px;
    flex: 0 0 auto;
    margin: 6px !important;
    margin-bottom: 6px !important;
    justify-content: center;
  }
  .testiwrapper {
    flex-direction: column;
  }
  .testiwrapper .testi-text {
    width: 100%;
    padding-left: 0px;
  }
  .awardss .ind-logo {
    margin-bottom: 30px;
    display: block;
    text-align: center;
  }
  .awardss .ind-logo img {
    height: 101px;
  }
  .join-us {
    flex-wrap: wrap;
    width: 100%;
  }
  .join-us h4 {
    font-size: 22px;
  }
  .hire-section {
    padding: 30px 0px;
  }
  .prodcard {
    padding: 15px;
    margin-bottom: 30px;
  }
  .aboutus-section {
    padding: 40px 0px 40px;
  }
  .media.whychoosemedia .mr-3 {
    display: none;
  }
  .sandip_blog {
    width: 100%;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .sandip_blog .entry-header h2 {
    font-size: 19px;
  }
  .sandip_blog .entry-summary p {
    margin-bottom: 0px;
  }
  .single .entry-header h1 {
    font-size: 20px;
  }
  .ab-img {
    margin-top: 30px;
  }
  .in-nutsell .cols {
    width: 50%;
  }
  h3,
  .h3 {
    font-size: 21px;
  }
  .projectheader .project-info {
    padding-left: 0px;
  }
  .projectheader .project-info {
    width: 100%;
  }
}
.cases_card {
  background-color: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  transition: all 0.5s ease-in-out 0s;
}
.cases_card:hover {
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
}
.cases_card .cases_type {
  color: #fff;
  border-radius: 20px;
  padding: 6px 15px;
  background-color: #fe3a45;
  top: -14px;
  position: relative;
}
.cases_card .cases_industry {
  color: #6a83a0 !important;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0;
}
.cases_card .cases_intro {
  color: #414a54 !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.single-portfolio-wrapper .cases_card .cases_intro p {
  margin: 0;
}
.single-portfolio-wrapper .cases_title {
  color: #04347b;
  margin-top: 16px;
  line-height: 35px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 17px;
}
.slider-cases__dl {
  display: grid;
  grid-template-columns: 155px 1fr;
  gap: 12px;
  font-size: 15px !important;
  align-items: start;
}
.slider-cases__dl dt,
.cases_title {
  color: #111111 !important;
}
.cases_title {
  color: #111111 !important;
  line-height: 36px;
}

.slider-cases__dl dd {
  margin: 0 !important;
  color: #111111 !important;
}
.slider-cases__dl dt {
  display: grid;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  column-gap: 8px;
  grid-template-columns: 20px 1fr;
  align-items: center;
  line-height: 100%;
}
.single-portfolio-wrapper .calculator-box {
  display: flex;
}
.single-portfolio-wrapper .calculator-box__paragraph {
  margin-bottom: 20px;
}
.single-portfolio-wrapper .calculator-box__heading {
  font-weight: 700;
}
.single-portfolio-wrapper .calculator-box__heading {
  font-size: 48px;
}
.single-portfolio-wrapper .calculator-box__description {
  font-size: 20px;
}
.single-portfolio-wrapper .icon-block_four {
  grid-template-columns: 4fr 4fr 4fr 4fr;
  gap: 30px 30px;
  margin: 0;
  padding: 0;
}
.single-portfolio-wrapper .icon-block {
  display: grid;
}
.single-portfolio-wrapper .icon-block_four .icon-item {
  max-width: 185px;
}
.single-portfolio-wrapper .icon-item {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  align-content: start;
}
.single-portfolio-wrapper .icon-block_orange .icon-item__icon {
  background-color: #ffffff;
}
.btn-readmore {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #04347b;
  margin-left: auto;
}
.btn-readmore:hover {
  color: #ffffff;
  background-color: #fe3a45;
}
.single-portfolio-wrapper .icon-item__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  width: 88px;
  border-radius: 12px;
  margin-bottom: 8px;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  flex-shrink: 0;
}
.single-portfolio-wrapper .icon-item__title {
  font-size: 20px;
  line-height: 150%;
  margin: 0;
  font-weight: 600;
  color: #22222a;
}
.single-portfolio-wrapper .icon-item__description {
  font-size: 16px;
  color: #606066;
  line-height: 150%;
  margin: 0;
}
.single-portfolio-wrapper .icon-item__icon::after {
  content: "";
  display: block;
  position: absolute;
  height: 40%;
  width: 60%;
  bottom: 0;
  left: 20%;
  background: rgba(34, 34, 42, 0.5);
  -webkit-filter: blur(10px);
  filter: blur(10px);
  -webkit-transform: translateZ(-1px);
  transform: translateZ(-1px);
}
.single-portfolio-wrapper .icon-block_six-horizontal {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin: 0;
  padding: 0;
}
.single-portfolio-wrapper .icon-block_blue .icon-block__item {
  display: flex;
}
.single-portfolio-wrapper .icon-block_blue .icon-item__icon img {
  width: 40px;
}
.single-portfolio-wrapper .icon-block_blue .icon-item__icon {
  background-color: #e1e9f5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  width: 68px;
  border-radius: 12px;
  margin-right: 8px;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  flex-shrink: 0;
}
.single-portfolio-wrapper .icon-block_blue .icon-item__text {
  color: #737373;
  margin-top: 5px;
}
.single-portfolio-wrapper .case-title {
  font-size: 55px;
  line-height: 60px;
}
.single-portfolio-wrapper .calculator-box,
.single-portfolio-wrapper .case-screen-slider,
.single-portfolio-wrapper .features,
.single-portfolio-wrapper .basic-info {
  padding: 80px 0;
}
.single-portfolio-wrapper .livelink {
  text-align: right;
}
.single-portfolio-wrapper .slick-prev:hover,
.single-portfolio-wrapper .slick-prev:focus,
.single-portfolio-wrapper .slick-next:hover,
.single-portfolio-wrapper .slick-next:focus,
.single-portfolio-wrapper .slick-arrow:hover {
  background-color: #233d63;
}
.single-portfolio-wrapper .slick-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #fe3a45;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 25;
}
.single-portfolio-wrapper .slick-slide > div {
  border-radius: 10px;
  overflow: hidden;
}
.single-portfolio-wrapper .slick-slide {
  margin: 20px;
  border-radius: 20px;
  overflow: hidden;
}
.single-portfolio-wrapper .theme-gradient {
  background: linear-gradient(180deg, #eaf8ff, #f6f6ff);
}
.single-portfolio-wrapper .testimonial h2 {
  font-size: 48px;
  line-height: 130%;
  color: #22222a;
  font-weight: 700;
}
.gradient-heading {
  background: linear-gradient(
    90deg,
    #04347b -45.08%,
    #04347b 23.14%,
    #04347b 23.16%,
    #fe3a45 80.14%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 16px;
  letter-spacing: 0.13em !important;
  line-height: 19px;
  text-transform: uppercase;
  font-weight: 500;
}
@media only screen and (max-width: 1024px) {
  .slick-prev {
    left: 20px;
  }
  .slick-next {
    right: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .single-portfolio-wrapper .icon-block_four,
  .icon-block_six-horizontal {
    grid-template-columns: 1fr 1fr !important;
  }
  .calculator-box__heading {
    font-size: 40px !important;
    margin-bottom: 20px !important;
    display: inline-block;
  }
  .calculator-box--blue,
  .case-screen-slider,
  .features,
  .single-portfolio-wrapper .basic-info {
    padding: 50px 0 !important;
  }
  .single-portfolio-wrapper .case-title {
    font-size: 35px;
    line-height: 46px;
  }
}
@media only screen and (max-width: 767px) {
  .project-cover {
    margin-top: 30px;
  }
  .single-portfolio-wrapper .livelink {
    text-align: left;
  }
  .single-portfolio-wrapper .icon-block_four {
    grid-template-columns: 1fr 1fr !important;
  }
  .single-portfolio-wrapper .icon-block_six-horizontal {
    grid-template-columns: 1fr !important;
  }
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a5a5a7;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #acacae;
}
@media screen and (max-width: 1200px) and (min-width: 991px) {
  .technologies-section .nav-pills li button {
    padding: 13px 15px;
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .technologies-section .nav-pills li button {
    padding: 10px 20px;
    font-size: 15px;
    width: 100%;
    white-space: nowrap;
  }
  .technologies-section .nav-pills {
    border-radius: 0px;
  box-shadow: none !important; 
    overflow-x: auto; /* Enables horizontal scrolling */
    scrollbar-height: thin; /* For Firefox */
  }
  /* Webkit browsers (Chrome, Safari, Edge) */
  .technologies-section .nav-pills::-webkit-scrollbar {
    height: 8px; /* Set scrollbar height */
  }
  .technologies-section .nav-pills::-webkit-scrollbar-thumb {
    background-color: #b4b2b2bc; /* Scrollbar color */

    border-radius: 10px; /* Rounded scrollbar */
  }
  .technologies-section .nav-pills::-webkit-scrollbar-thumb:hover {
    background: #7f7f80;
  }
}
.required-asterisk {
  color: red !important;
}
/* input file css for Resume */
.resume-upload-box {
  border: 1px dashed;
  border-color: #ccc;
  background-color: #f9f9f9;
  padding: 20px;
  padding-bottom: 2px;
  text-align: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  cursor: pointer;
}
.resume-upload-link {
  color: #007bff;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
}
.resume-upload-box:hover {
  border: 1px dashed #909090 !important;
}
.resume-upload-input {
  display: none;
}
.resume-upload-description {
  color: #777;
  font-size: 14px;
}
.more-details {
  color: #fe3a45;
  margin-bottom: 0px;
}
.awsm-job-more:hover .more-details {
  color: #0d6efd;
}
.error {
  color: red;
  font-size: 0.875rem;
}
.awsm-job-form-field.error {
  border: 1px solid red;
  background-color: #ffe6e6;
}
.border-non-privacy-policy {
  --bs-card-border-color: unset !important;
}
