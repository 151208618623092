.awsm-job-item {
  background: #fff;
  padding: 20px;
  font-size: 16px !important;
}
a.awsm-job-item {
  text-decoration: none !important;
}
.awsm-grid-item .awsm-job-item {
  margin-bottom: 30px;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
  border: 1px solid #dddfe3;
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-grow: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.awsm-grid-item .awsm-job-item:focus,
.awsm-grid-item .awsm-job-item:hover {
  -webkit-box-shadow: 0 3px 15px -5px rgb(0 0 0 / 20%);
  box-shadow: 0 3px 15px -5px rgb(0 0 0 / 20%);
}
.awsm-grid-item .awsm-job-featured-image {
  margin-bottom: 14px;
}
.awsm-job-item h2.awsm-job-post-title {
  margin: 0 0 15px;
  font-size: 20px;
  text-align: left;
}
.awsm-job-item h2.awsm-job-post-title a {
  font-size: 18px;
  color: #fe3a45;
  transition: color 0.3s ease;
}
.awsm-job-item h2.awsm-job-post-title a:hover {
  color: #007bff;
}
.awsm-lists {
  border: 1px solid #ededed;
}
.awsm-list-item {
  width: 100%;
}
.awsm-list-item h2.awsm-job-post-title {
  margin-bottom: 0;
}
.awsm-list-item .awsm-job-featured-image {
  float: left;
  margin-right: 10px;
}
.awsm-list-item .awsm-job-featured-image img {
  width: 50px;
  height: 50px;
}
.awsm-list-item .awsm-job-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}
.awsm-list-item .awsm-job-item::after {
  content: "";
  display: table;
  clear: both;
}
.awsm-list-left-col {
  float: left;
  width: 50%;
}
.awsm-list-right-col {
  float: left;
  width: 50%;
  text-align: right;
}
.awsm-list-item .awsm-job-specification-wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.awsm-list-item .awsm-job-specification-item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px 0 0;
}
a.awsm-job-item .awsm-job-specification-item {
  color: #4c4c4c;
}
.awsm-list-item .awsm-job-more-container {
  display: inline-block;
  vertical-align: middle;
}
.awsm-job-more-container .awsm-job-more span::before {
  content: "\002192";
}
.awsm-lists .awsm-jobs-pagination {
  margin-top: 30px;
}
.awsm-job-specification-item > [class^="awsm-job-icon-"] {
  margin-right: 6px;
}
.awsm-job-specification-term::after {
  content: ", ";
}
.awsm-job-specification-term:last-child::after {
  content: "";
}
/* responsive */
@media (max-width: 1024px) {
  .awsm-grid-col-4 .awsm-grid-item {
    width: 33.333%;
  }
}
@media (max-width: 992px) {
  .awsm-job-single-wrap.awsm-col-2 .awsm-job-content {
    width: 100%;
    padding-right: 0;
  }
  .awsm-job-single-wrap.awsm-col-2 .awsm-job-form {
    width: 100%;
    padding-left: 0;
  }
}
@media (max-width: 768px) {
  .awsm-grid-col-3 .awsm-grid-item,
  .awsm-grid-col-4 .awsm-grid-item,
  .awsm-grid-item {
    width: 50%;
  }
  .awsm-list-left-col {
    width: 100%;
    padding-bottom: 10px;
  }
  .awsm-list-right-col {
    width: 100%;
    text-align: left;
  }
}
@media (max-width: 648px) {
  .awsm-grid-col-2 .awsm-grid-item,
  .awsm-grid-col-3 .awsm-grid-item,
  .awsm-grid-col-4 .awsm-grid-item,
  .awsm-grid-item {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .awsm-list-item .awsm-job-specification-wrapper {
    display: flex;
  }
  .awsm-list-item .awsm-job-more-container {
    margin-left: auto;
  }
}
@media (max-width: 576px) {
  .awsm-list-item .awsm-job-specification-wrapper {
    display: block;
    padding-bottom: 5px;
    float: none;
  }
  .awsm-list-item .awsm-job-more-container {
    display: block;
    float: none;
  }
}