.entry-header1 {
  background: #e2ecfc;
  padding: 10px 20px !important;
}
.blog_metas {
  margin-top: 20px;
  padding-left: 0px;
}
/* blog  */
.projeheader .post-thumbnail > img {
  width: 100%;
  height: auto;
}
.blog_body .entry-header,
.blog_body .entry-summary,
.entry-content {
  padding-left: 15px;
  padding-right: 15px;
}
.blog_body .entry-summary {
  padding-bottom: 15px;
}
.blog_list {
  background: #fff;
  display: block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.artcalgrid {
  display: flex;
  flex-wrap: wrap;
}
.artcalgrid .blog_thumbnails img {
  height: 300px;
  object-fit: cover;
}
.sandip_blog .entry-header h2 {
  font-size: 21px;
}
.sandip_blog .entry-header h2 a {
  height: 50px;
  overflow: hidden;
}
.sandip_blog {
  width: 50%;
  padding: 15px;
  margin-top: 40px;
}
.single .sandip_blog {
  width: 100%;
  margin-top: 3rem;
  padding: 0;
}
.sandip_blog .entry-summary p {
  color: #666666;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.readmore_btn {
  color: #000;
}
.readmore_btn:hover {
  text-decoration: none;
  color: #555;
}
.page_inner_title {
  background: #31328b;
  text-align: center;
  padding: 50px 0px;
}
.page_title {
  color: #fff;
  text-transform: uppercase;
  margin: 0px;
}
.sidebar-1-area {
  border: none !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.post_date .btn-primary {
  border-radius: 20px;
  padding: 6px 15px;
  color: #fff;
}
.post_date .btn-primary:hover {
  color: #fff;
}
.blog_metas {
  margin-top: -20px;
  padding-left: 15px;
}
.single .blog_metas {
  margin-top: 20px;
  padding-left: 0px;
}
.single .entry-content {
  padding-left: 0px;
}
.single .entry-header {
  background: #e2ecfc;
  padding: 10px 20px !important;
}
.single .blog_thumbnails {
  margin-bottom: 30px;
}
.single .entry-header h1 {
  margin-bottom: 0px;
  font-size: 25px;
}
.auth_n_cat {
  margin-top: 15px;
  margin-bottom: 10px;
  float: left;
  width: 100%;
}
.sperator {
  width: 20px;
  text-align: center;
  color: #999;
}
.auth_n_cat span {
  float: left;
  line-height: 1;
}
.tags-links {
  padding-left: 15px;
}
.data_blog h3 {
  color: #262626;
  font-size: 20px !important;
}
.data_blog p {
  color: #959595;
}
.data_blog {
  padding: 15px 15px 30px 15px;
}
.nav-links {
  display: flex;
  justify-content: space-between;
}
.nav-links a {
  display: inline-block;
  padding: 6px 10px;
  color: #04347b;
  border: 1px solid #04347b;
  border-radius: 25px;
  margin-top: 15px;
}
.comments-area.card {
  border: none !important;
  margin-top: 30px;
}
.single .archivemeta {
  display: none;
}
.logged-in-as {
  padding-left: 15px;
  margin-top: 40px;
}
.single .blog_list {
  box-shadow: none;
}
.cate_badge {
  padding-left: 10px;
  border-left: 1px solid #ddd;
  margin-left: 10px;
}
.service-infobox {
  margin-bottom: 30px;
}
.service-infobox img {
  height: 90px;
  width: auto;
  margin-bottom: 24px;
}
body .awsm-job-form-inner {
  background: #fff;
  border: none;
  padding: 30px;
  padding-bottom: 13px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
}
body .awsm-job-form-control {
  border: 1px solid #ddd;
  padding: 6px 10px;
  border-radius: 5px;
}
:hover .awsm-job-form-control {
  border: 1px solid #a50101;
}
.awsm-job-form-inner h2 {
  font-size: 21px;
}
.awsm-job-form-inner h2 {
  margin: 0 0 30px;
}
.awsm-job-form-group label {
  display: block;
  margin-bottom: 10px;
}
.awsm-job-form-group {
  margin-bottom: 20px;
}
.awsm-job-form-control {
  display: block;
  width: 100%;
}
.awsm-job-single-wrap.awsm-col-2 .awsm-job-content {
  float: left;
  width: 55%;
  padding-right: 15px;
}
.awsm-job-content {
  padding-bottom: 32px;
}
.awsm-job-single-wrap.awsm-col-2 .awsm-job-form {
  float: left;
  width: 45%;
  padding-left: 15px;
}
.entry-content {
  padding-left: 4px;
  padding-right: 15px;
}
.awsm-job-content {
  width: 55%;
}
.awsm-job-form {
  width: 45%;
}
@media (max-width: 992px) {
  .awsm-job-content,
  .awsm-job-form {
    width: 100%;

  }
}